import { LitElement, html } from "lit";
import { SMSElementMix, StatelessMessage } from "stateful-messaging-system";
import { customElement } from "lit/decorators.js";

export class LanguageChanged extends StatelessMessage {
  lang: string;
  translationLoaded: Promise<void> = Promise.resolve();
  translations: any;
  error: boolean = false;
  exception: any;

  constructor(lang: string) {
    super();
    if (!lang) return;
    this.lang = lang;
    this.translationLoaded = this.loadTranslations();
  }

  async loadTranslations() {
    try {
      let resp = await fetch(`/translations/${this.lang}.json`);
      if (resp.ok) this.translations = await resp.json();
      else this.error = true;
    } catch (ex) {
      this.exception = ex;
      this.error = true;
    }
    if (this.error)
      console.warn(
        `Translations can't be loaded. Language: ${this.lang} ; Exception: ${this.exception?.message}`
      );
  }
}

@customElement("tds-localize")
export class Localize extends SMSElementMix(LitElement) {
  innerContent: any;

  connectedCallback() {
    super.connectedCallback();
    this.innerContent = this.innerText.trim();
    this.innerHTML = "";
    this.subscribe(LanguageChanged, async (l: LanguageChanged) => {
      try {
        await l.translationLoaded;
        if (l.error) return;

        let t = l.translations[this.innerContent];
        if (!t) t = l.translations[this.innerContent.toLowerCase()];
        if (!t) t = this.innerContent;
        this.innerContent = t;
        this.requestUpdate();
      } catch {}
    }).sendLastState();
  }

  createRenderRoot() {
    return this;
  }

  render() {
    return html`${this.innerContent}`;
  }
}
